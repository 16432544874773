/*    
<summary>
   This component is Managing Users Logs & studying logs using Filter.
   Developer: Mohammad Saquib Khan, Created Date:25-Aug-2024,
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import { observer } from "mobx-react";
import React, { ReactNode, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import noMessageIcon from "../../../assets/Images/svg/018-empty.svg";
import {
  searchInTargetTypeEnum,
  searchInTypeEnum,
} from "../../../constants/constants";
import userSettingEnum from "../../../constants/enums/user-setting-enum";
import userTypeEnum from "../../../constants/enums/user-type-enum";
import { useStore } from "../../../contexts/store-provider";
import {
  getIsTenantAccessFromLocalStorage,
  getOrgIdFromToken,
  getUserEmailFromToken,
  getUserTypeFromLocalStorage,
} from "../../../helpers/localstorage-helper";
import IPageInfo, { IPageLimitInfo } from "../../../models/ICommon";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import Grid from "../../../shared-components/Grid/Grid";
import Pagination from "../../../shared-components/Grid/Pagination";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import "./user-logs.css";
import toast from "react-hot-toast";

const UserLogs = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { logsStore, preferencesStore, authStore } = useStore();
  const {
    inProgress,
    error,
    allUserLogsList,
    GetUserLogsListService,
    userLogsList,
    getAllUserLogsState,
    GetUserLogsFilterListService,
  } = logsStore;
  const {
    userSetting,
    setUserSetting,
    language,
    islanguageChanged,
    updateLanguageChangedFlag,
    setTenantAccess,
    getTenantAccess,
    resetUnwantedModules,
    getUserLogsFilter,
    setUserLogsFilter,
  } = preferencesStore;
  const { getUserType, getPermissionType } = authStore;
  const [selectedId, setSelectedId] = useState<number>(0);
  let lang: string;
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allUsersLogList: Array<any> = allUserLogsList;
  const pageLimitOptions: Array<number> = [10, 50, 100];

  const [filter, setFilter] = useState<any>(getUserLogsFilter.IsFilterActive);
  const [userId, setUserId] = useState(getUserLogsFilter.UserId);
  const [company, setCompany] = useState(getUserLogsFilter.Company);
  const [target, setTarget] = useState(getUserLogsFilter.Target);
  const [targetItems, setTargetItems] = useState(getUserLogsFilter.TargetItems);
  const [type, setType] = useState(getUserLogsFilter.Type);
  const [typeItems, setTypeItems] = useState(getUserLogsFilter.TypeItems);
  const [content, setContent] = useState(getUserLogsFilter.Content);
  const [branchName, setBranchName] = useState(getUserLogsFilter.Content);

  const handleUserId = (event: any) => {
    setUserId(event.target.value);
  };

  const handleCompany = (event: any) => {
    setCompany(event.target.value);
  };

  const handleTarget = (event: any) => {
    setTarget(event.target.value);
    if (event?.target.value !== "") {
      let extractedEnums = searchInTargetTypeEnum(event?.target.value?.trim());
      if (extractedEnums?.length == 0) {
        setTargetItems([-1]);
      } else {
        setTargetItems(searchInTargetTypeEnum(event?.target.value?.trim()));
      }
    } else {
      setTargetItems([]);
    }
  };

  const handleType = (event: any) => {
    setType(event.target.value);
    if (event?.target.value?.trim() !== "") {
      let extractedEnums = searchInTypeEnum(event?.target.value?.trim());
      if (extractedEnums?.length == 0) {
        setTypeItems([-1]);
      } else {
        setTypeItems(searchInTypeEnum(event?.target.value?.trim()));
      }
    } else {
      setTypeItems([]);
    }
  };

  const handleContent = (event: any) => {
    setContent(event.target.value);
  };

  const handleBranchName = (event: any) => {
    setBranchName(event.target.value);
  };

  const [pageLimitInfo, setPageLimit] = useState<IPageLimitInfo>({
    pageLimit: userSetting.pageLimitConfig,
    isPageLimitChange: false,
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
    isPagerChange: false,
    isRefreshPage: false,
  });

  const onPageChanged = (pageNumber: number) => {
    setPageInfo({ ...pageInfo, currentPage: pageNumber, isPagerChange: true });
  };

  const setIsRefreshPage = () => {
    setPageInfo({ ...pageInfo, isRefreshPage: false });
  };

  const onPageLimitChanged = (evt: any) => {
    let pages: number;
    pages = props.data && Math.ceil(props.data.length / evt.target.value);
    setPageInfo({
      ...pageInfo,
      currentPage: 1,
      totalPages: pages,
      isPagerChange: false,
    });
    setPageLimit({
      pageLimit: parseInt(evt.target.value),
      isPageLimitChange: true,
    });
    setUserSetting(userSettingEnum.UserLimitConfig, parseInt(evt.target.value));
  };

  /**
   * This function calls the "getAllGroup" store funtion that get configuration list from API
   */
  const callGetUserListService = () => {
    let userType: string = getUserTypeFromLocalStorage();
    if (
      userType == userTypeEnum[0] ||
      userType == userTypeEnum[1] ||
      userType == userTypeEnum[4]
    ) {
      getUserLogsFilter.IsFilterActive == true
        ? GetUserLogsFilterListService(
            pageInfo.currentPage,
            pageLimitInfo.pageLimit,
            getUserLogsFilter.UserId,
            getUserLogsFilter.Company,
            target?.trim() == ""
              ? targetItems
              : targetItems?.length > 0
              ? targetItems
              : [-1],
            type?.trim() == ""
              ? typeItems
              : typeItems?.length > 0
              ? typeItems
              : [-1],
            content,
            branchName
          )
        : GetUserLogsListService(pageInfo.currentPage, pageLimitInfo.pageLimit);
    } else {
      GetUserLogsFilterListService(
        pageInfo.currentPage,
        pageLimitInfo.pageLimit,
        getUserEmailFromToken(),
        getOrgIdFromToken(),
        target?.trim() == ""
          ? targetItems
          : targetItems?.length > 0
          ? targetItems
          : [-1],
        type?.trim() == ""
          ? typeItems
          : typeItems?.length > 0
          ? typeItems
          : [-1],
        content,
        branchName
      );
    }
  };

  useEffect(() => {
    if (getUserLogsFilter.IsFilterActive) callGetUserListService();
    else callGetUserListService();
  }, [getUserLogsFilter]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  useEffect(() => {
    if (islanguageChanged) {
      setFilterState({
        UserId: "",
        Company: "",
        Target: "",
        TargetItems: [],
        Type: "",
        TypeItems: [],
        Content: "",
        BranchName: "",
        IsFilterActive: false,
      });
      // callGetUserListService();
      updateLanguageChangedFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islanguageChanged]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "GroupList" of the useEffect changed.
   */
  useEffect(() => {
    setPageInfo({
      ...pageInfo,
      totalPages: userLogsList?.PagingDetails?.TotalPages,
      isPagerChange: false,
    });
    setDisablePagination(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserLogsList]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageInfo.currentPage" of the useEffect changed.
   */
  useEffect(() => {
    if (pageInfo.isPagerChange) {
      callGetUserListService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.currentPage]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "pageLimitInfo.pageLimit" of the useEffect changed.
   */
  useEffect(() => {
    if (pageLimitInfo.isPageLimitChange) {
      callGetUserListService();
      setPageLimit({ ...pageLimitInfo, isPageLimitChange: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimitInfo.pageLimit]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "language" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (language !== lang) {
  //     callGetUserListService();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [language]);

  /**
   * This function provides manual refresh functionality
   */
  const refreshClickHandler = () => {
    callGetUserListService();
  };

  const setFilterState = (data: any) => {
    setFilter(data.IsFilterActive);
    setCompany(data.Company);
    setUserId(data.UserId);
    setTarget(data.Target);
    setTargetItems(data.TargetItems);
    setType(data.Type);
    setTypeItems(data.TypeItems);
    setContent(data.Content);
    setBranchName(data.BranchName);
    setUserLogsFilter(data);
  };

  /**
   * The column constant defines the column description for the user grid (table)
   */
  const columns: any = [
    {
      dataField: "Id",
      text: formatMessage("label_id"),
      hidden: true,
    },
    {
      dataField: "DateTime",
      text: formatMessage("action_timestamp"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "UserId",
      text: formatMessage("user_id"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Company",
      text: formatMessage("tenantId"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Branch",
      text: formatMessage("branch_name"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "ParkingLot",
      text:
        formatMessage("parking_lot_title") +
        " / " +
        formatMessage("Integrator"),
        // +
        // " / " +
        // formatMessage("route"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
    },
    {
      dataField: "Target",
      text: formatMessage("target"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? formatMessage(cell) : undefined;
      },
      formatter: (cell: any, row: any) => {
        if (cell) {
          return <FormattedMessage id={cell} />;
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "Type",
      text: formatMessage("type"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? formatMessage(cell) : undefined;
      },
      formatter: (cell: any, row: any) => {
        return <FormattedMessage id={cell} />;
      },
    },
    {
      dataField: "Content",
      text: formatMessage("content"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? formatMessage(cell) : undefined;
      },
      formatter: (cell: any, row: any) => {
        return cell ? <FormattedMessage id={cell} /> : cell;
      },
    },
    {
      dataField: "Data",
      text: formatMessage("data"),
      title: (cell: string, row: any) => {
        return cell?.length > 10 ? cell : undefined;
      },
      formatter: (
        cell: any,
        row: { Data: { [key: string]: any } }
      ): ReactNode => {
        if (!row.Data || typeof row.Data !== "object") {
          return null;
        }

        const renderValue = (value: any, indentLevel: number = 0) => {
          const indentStyle = { marginLeft: `${indentLevel * 3}px` };

          if (Array.isArray(value)) {
            return (
              <div style={indentStyle}>
                [
                <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
                  {value.map((item, index) => (
                    <li key={index}>{renderValue(item, indentLevel + 1)}</li>
                  ))}
                </ul>
                ]
              </div>
            );
          } else if (typeof value === "object" && value !== null) {
            return (
              <div style={indentStyle}>
                {"{"}
                <div style={{ paddingLeft: "20px" }}>
                  {Object.entries(value).map(([key, val]) => (
                    <div key={key}>
                      <strong>{key}</strong>:{" "}
                      {renderValue(val, indentLevel + 1)}
                    </div>
                  ))}
                </div>
                {"}"}
              </div>
            );
          } else {
            return <span style={indentStyle}>{value}</span>;
          }
        };

        return (
          <>
            {Object.entries(row.Data).map(([key, value]) => (
              <div key={key} className="data-comment">
                <strong>{key}</strong>: {renderValue(value)}
              </div>
            ))}
          </>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="icon-nav navbar-fixed-top device-nav-bar userTopNav">
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="page_title_wrap">
              <h1 className="page_title">
                <FormattedMessage id="UserLogs" />
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <FormattedMessage id="Home" />
                </li>
                {getIsTenantAccessFromLocalStorage() == true && (
                  <li className="breadcrumb-item">
                    {getTenantAccess.tenantName}
                  </li>
                )}
                {/* <li className="breadcrumb-item active" aria-current="page">
                  <FormattedMessage id="UserLogs" />
                </li> */}
              </ol>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ul className="add-list">
              {(getUserTypeFromLocalStorage() == userTypeEnum[0] ||
                getUserTypeFromLocalStorage() == userTypeEnum[1] ||
                getUserTypeFromLocalStorage() == userTypeEnum[2] ||
                getUserTypeFromLocalStorage() == userTypeEnum[4]) && (
                <>
                  <li
                    className="filter-dropdown"
                    title={formatMessage("filter")}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btn btn-light"
                        aria-expanded="false"
                      >
                        <svg
                          className="svg-icon"
                          width="20px"
                          height="20px"
                          viewBox="0 0 1024 1024"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M859.02 234.524l0.808-0.756 0.749-0.813c27.047-29.356 33.876-70.34 17.823-106.957-15.942-36.366-50.416-58.957-89.968-58.957H163.604c-38.83 0-73.043 22.012-89.29 57.444-16.361 35.683-10.632 76.301 14.949 106.004l0.97 1.126 280.311 266.85 2.032 312.074c0.107 16.502 13.517 29.805 29.995 29.805l0.2-0.001c16.568-0.107 29.912-13.626 29.804-30.194l-2.198-337.564-296.478-282.241c-9.526-11.758-11.426-26.933-5.044-40.851 6.446-14.059 19.437-22.452 34.75-22.452h624.828c15.6 0 28.69 8.616 35.017 23.047 6.31 14.391 3.924 29.831-6.354 41.497l-304.13 284.832 1.302 458.63c0.047 16.54 13.469 29.916 29.998 29.915h0.087c16.568-0.047 29.962-13.517 29.915-30.085L573.04 502.36l285.98-267.836z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M657.265 595.287c0 16.498 13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.498 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997H687.262c-16.498 0-29.997 13.499-29.997 29.997z m273.894 138.882H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z m0 168.878H687.262c-16.498 0-29.997 13.499-29.997 29.997s13.499 29.997 29.997 29.997h243.897c16.498 0 29.997-13.499 29.997-29.997 0-16.498-13.499-29.997-29.997-29.997z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="filter-menu">
                        <Dropdown.ItemText>
                          <div className="px-3 pt-3">
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="tenantId"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={company}
                                  className="form-control"
                                  name="Company"
                                  placeholder={formatMessage("tenantId")}
                                  onChange={(e) => handleCompany(e)}
                                />
                              </a>
                            </div>
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="user_id"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={userId}
                                  className="form-control"
                                  name="UserId"
                                  placeholder={formatMessage("user_id")}
                                  onChange={(e) => handleUserId(e)}
                                />
                              </a>
                            </div>
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="branch_name"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={branchName}
                                  className="form-control"
                                  name="BranchName"
                                  placeholder={formatMessage("branch_name")}
                                  onChange={(e) => handleBranchName(e)}
                                />
                              </a>
                            </div>
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="target"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={target}
                                  className="form-control"
                                  name="Target"
                                  placeholder={formatMessage("target")}
                                  onChange={(e) => handleTarget(e)}
                                />
                              </a>
                            </div>
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="type"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={type}
                                  className="form-control"
                                  name="Type"
                                  placeholder={formatMessage("type")}
                                  onChange={(e) => handleType(e)}
                                />
                              </a>
                            </div>
                            <div className="form-group mb-1 row d-flex">
                              <Label
                                className="label-style"
                                required={false}
                                label="content_tbd"
                              />
                              <a className="dropdown-item">
                                <input
                                  type="text"
                                  value={content}
                                  className="form-control"
                                  name="Content"
                                  disabled={true}
                                  placeholder={formatMessage("content")}
                                  onChange={(e) => handleContent(e)}
                                />
                              </a>
                            </div>
                          </div>
                        </Dropdown.ItemText>
                        <Dropdown.Divider />
                        <div className="text-end me-3">
                          <Dropdown.Item
                            as="button"
                            className="btn btn-primary cancel-btn-filter d-inline-flex me-2"
                            onClick={props.onModalClosed}
                            disabled={props.addUpdateFolderLoading}
                          >
                            <FormattedMessage id="button_cancel" />
                          </Dropdown.Item>

                          <button
                            data-testid="AddConfigurationButton"
                            className="btn btn-secondary d-inline-flex"
                            type="button"
                            onClick={() => {
                              userId?.trim() !== "" ||
                              company?.trim() !== "" ||
                              target?.trim() !== "" ||
                              type?.trim() !== "" ||
                              branchName?.trim() !== ""
                                ? setFilterState({
                                    UserId: userId,
                                    Company: company,
                                    Target: target,
                                    TargetItems: targetItems,
                                    Type: type,
                                    TypeItems: typeItems,
                                    Content: content,
                                    BranchName: branchName,
                                    IsFilterActive: true,
                                  })
                                : toast.error(
                                    formatMessage(
                                      "select_at_least_one_parameter"
                                    )
                                  );
                            }}
                          >
                            <FormattedMessage id="apply" />
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </>
              )}

              {getUserLogsFilter.IsFilterActive == true && (
                <div>
                  <a
                    data-testid="AddConfigurationButton"
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => {
                      setFilterState({
                        UserId: "",
                        Company: "",
                        Target: "",
                        TargetItems: [],
                        Type: "",
                        TypeItems: [],
                        Content: "",
                        BranchName: "",
                        IsFilterActive: false,
                      });
                    }}
                    title={formatMessage("reset")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      width="20px"
                      height="20px"
                      className="me-2"
                      viewBox="0 0 122.88 110.668"
                      enable-background="new 0 0 122.88 110.668"
                    >
                      <g>
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M91.124,15.645c12.928,0,23.406,10.479,23.406,23.406 c0,12.927-10.479,23.406-23.406,23.406c-12.927,0-23.406-10.479-23.406-23.406C67.718,26.125,78.197,15.645,91.124,15.645 L91.124,15.645z M2.756,0h117.322c1.548,0,2.802,1.254,2.802,2.802c0,0.848-0.368,1.622-0.996,2.139l-10.667,13.556 c-1.405-1.375-2.95-2.607-4.614-3.672l6.628-9.22H9.43l37.975,46.171c0.59,0.516,0.958,1.254,0.958,2.102v49.148l21.056-9.623 V57.896c1.651,1.9,3.548,3.582,5.642,4.996v32.133c0,1.105-0.627,2.064-1.586,2.506l-26.476,12.758 c-1.327,0.773-3.023,0.332-3.798-1.033c-0.258-0.441-0.368-0.92-0.368-1.4V55.02L0.803,4.756c-1.07-1.106-1.07-2.839,0-3.945 C1.355,0.258,2.056,0,2.756,0L2.756,0z M96.93,28.282c1.328-1.349,3.489-1.355,4.825-0.013c1.335,1.342,1.341,3.524,0.013,4.872 l-5.829,5.914l5.836,5.919c1.317,1.338,1.299,3.506-0.04,4.843c-1.34,1.336-3.493,1.333-4.81-0.006l-5.797-5.878l-5.807,5.889 c-1.329,1.349-3.489,1.355-4.826,0.013c-1.335-1.342-1.341-3.523-0.013-4.872l5.83-5.913l-5.836-5.919 c-1.317-1.338-1.3-3.507,0.04-4.843c1.339-1.336,3.492-1.333,4.81,0.006l5.796,5.878L96.93,28.282L96.93,28.282z"
                        />
                      </g>
                    </svg>
                    <FormattedMessage id="reset_filter" />
                  </a>
                </div>
              )}

              <li title={formatMessage("refresh")}>
                <a
                  data-toggle="modal"
                  className="btn btn-light"
                  onClick={() => refreshClickHandler()}
                >
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-repeat m-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path
                      fillRule="evenodd"
                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {<FormLoader loading={inProgress || getAllUserLogsState.inProgress} />}
      {allUsersLogList?.length > 0 ? (
        <div>
          <div className="card">
            <div className="tableDesign row-eq-height tableDevices ">
              <div className="table-responsive" data-testid="table">
                {allUsersLogList && (
                  <Grid
                    data={allUsersLogList}
                    columns={columns}
                    keyField="Id"
                    loading={false}
                  />
                )}
              </div>
            </div>
          </div>
          {allUsersLogList?.length > 0 ? (
            <div className="row no-gutters p-2 tableFooter">
              <div className="col-sm-6 pt-1">
                <FormattedMessage id="show" />
                <select
                  data-testid="pageSelect"
                  className="pageLimit"
                  onChange={onPageLimitChanged}
                  value={pageLimitInfo.pageLimit}
                >
                  {pageLimitOptions.map((op: any) => (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 d-flex justify-content-sm-end">
                <Pagination
                  isRefreshPage={false}
                  totalPages={pageInfo.totalPages}
                  pageLimit={pageLimitInfo.pageLimit}
                  onPageChanged={onPageChanged}
                  disablePagination={disablePagination}
                  setIsRefreshPage={setIsRefreshPage}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="message text-center">
          <img src={noMessageIcon} alt="" className="no_message" />
          <p className="noData text-muted text-center h4 mb-0">
            {filter == true ? (
              <FormattedMessage id="no_filter_user_logs" />
            ) : (
              <FormattedMessage id="no_user_logs" />
            )}
          </p>
          {filter == true ? (
            <p className="mb-3">
              {formatMessage("no_filter_user_logs_message")}
            </p>
          ) : (
            <p className="mb-3">{formatMessage("no_user_logs_message")}</p>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default observer(UserLogs);
